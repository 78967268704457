@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Bebas+Neue&family=Montserrat:wght@300;400&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  line-height: 1.3;
  color: #516171;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
